export enum AppSections {
  Emaginator = 'emaginator',
  Visualizer = 'visualizer',
  Configurator = 'configurator',
}

export enum ConfigurationTypes {
  Old = 'old',
  New = 'new',
}

export enum StatusTypes {
  Yes = 'yes',
  No = 'no',
}

export enum TutorialType {
  Tap = 'tap',
  Pinch = 'pinch',
}
