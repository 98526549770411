import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { EventService } from '../Events/events.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  isLoading = false;

  constructor(
    private readonly loadingController: LoadingController,
    private readonly toastController: ToastController,
    private readonly events: EventService
  ) {}
  async toastWithButtons(message, color) {
    const toast = await this.toastController.create({
      animated: true,
      position: 'top',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          handler: () => null,
        },
      ],
      color,
      cssClass: 'toast',
      duration: 2000,
      keyboardClose: true,
      message,
    });
    toast.present();
  }
  async showLoaderWithText(model = 'Jeep') {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // mode: 'ios',
        spinner: null,
        // message: 'msg',
        // eslint-disable-next-line max-len
        message: `<ion-img src="assets/img/one3d-jeep-loader-new.gif"></ion-img><p class="one3d-loader-bottom-text">${model} will be yours to explore<br>In just a couple of seconds!</p>`,
        cssClass: 'one3d-jeep-loader-opaque',
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => {});
          }
        });
      });
  }
  async showLoader(msg = 'Please wait...') {
    this.isLoading = true;
    this.events.publish('events', { showLoader: true });
    // return await this.loadingController
    //   .create({
    //     // mode: 'ios',
    //     spinner: null,
    //     // message: 'msg',
    //     message: '<ion-img src="assets/images/loader.svg"></ion-img>',
    //     cssClass: 'one3d-loader-visualizer',
    //   })
    //   .then((a) => {
    //     a.present().then(() => {
    //       if (!this.isLoading) {
    //         a.dismiss().then(() => {});
    //       }
    //     });
    //   });
  }
  async hideLoader() {
    this.isLoading = false;
    this.events.publish('events', { hideLoader: true });
    // this.loadingController
    //   .getTop()
    //   .then((v) => (v ? this.doStopLoader() : null));
  }
  doStopLoader() {
    this.loadingController.dismiss();
  }
}
